<template>
  <div>
    <hero-bar>Novo processo</hero-bar>

    <div v-if="!enviado">
      <section class="section is-main-section">
          <div class="columns">
            <div class="column">
              <b-field label="Referência do processo">
                <b-input v-model="body.Referencia" required></b-input>
              </b-field>

            </div>
          </div>
          <div class="columns">
            <div class="column is-three-fifths">
              <b-field label="Selecione o cliente">
                <b-autocomplete
                    v-model="inputCliente"
                    :data="clientes"
                    placeholder="Nome do cliente..."
                    field="Name"
                    :loading="isFetchingClient"
                    @typing="getClientData"
                    @select="selecionaCliente"
                    required
                >

                    <template slot-scope="props">
                      <div class="media">
                        <div class="media-content">
                          {{ props.option.Name }} <br><small>Email {{ props.option.Email }}</small>
                        </div>
                      </div>
                    </template>

                    <template #empty>Nenhum cliente encontrado</template>
                </b-autocomplete>
              </b-field>
            </div>
            <div class="column">
              <div v-if="clienteSelecionado && clienteSelecionado.CNPJs">
                <b-field label="Selecione o CNPJ do cliente" >
                  <b-select v-model="body.IDCNPJ" placeholder="Selecione o CNPJ">
                      <option
                          v-for="option in clienteSelecionado.CNPJs"
                          :value="option.IDCNPJ"
                          :key="option.IDCNPJ">
                          {{ option.Descricao }} - {{ formatCNPJ(option.CNPJ) }}
                      </option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
      </section>

      <upload v-if="items.length === 0" :disabledUpload="disabledUpload" @onUpdate="updateItens" />

      <section class="section" v-else><p>Processando...</p></section>
    </div>

    <section class="section has-text-centered" v-if="enviado && status === 'sucesso'">
      <h1 class="title">Processo cadastrado com sucesso!</h1>
      <h2 class="subtitle">Veja mais informações sobre o processo cadastrado</h2>

      <router-link :to="{ name:'home' }" class="button is-medium ">
        <b-icon icon="arrow-left" size="is-medium"/>
        <p>Ir para lista de processos</p>
      </router-link>

      <router-link :to="{ name:'processo.detalhes', params: { id: resposta.IDProcesso } }" class="button is-medium is-primary ml-4">
        <p>Ir para detalhe do processo</p>
        <b-icon icon="arrow-right" size="is-medium"/>
      </router-link>
    </section>

    <section class="section" v-if="enviado && status === 'pendente'">
    <!-- <section class="section"> -->
      <h1 class="title">Existem produtos não cadastrados na planilha</h1>
      <h2 class="subtitle">Cadastre os produtos para proseguir</h2>
      <router-link :to="`/clientes/${this.IDCliente}/cadastro-produto`" class="button is-primary">Cadastrar Produtos</router-link>

    </section>

   <b-loading :is-full-page="true" v-model="isLoadingPage" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import HeroBar from '@/components/HeroBar'
import {
  novoProcesso,
  cadastroProdutos,
  searchCliente,
  formatImportedList,
  equalsIgnoreOrder,
  formatCNPJ,
  validaDadosProcesso
} from '@/core'
import debounce from 'lodash/debounce'
import Upload from '@/components/Upload'

export default {
  name: 'NovoProcesso',
  components: {
    HeroBar,
    Upload
  },
  data () {
    return {
      createdReadable: null,
      items: [],
      body: {},
      name: '',
      clientes: [],
      enviado: false,
      resposta: null,
      status: null,
      produtosPendentes: [],
      novosProdutos: [],
      isFetchingClient: false,
      clienteSelecionado: null,
      inputCliente: null,
      validKeys: ['REF', 'QTDE', 'PESOTOT'],
      isLoadingPage: false
    }
  },
  computed: {
    disabledUpload () { return !this.body.IDCNPJ || !this.body.IDCliente || !this.body.Referencia },
    IDCliente () { return this.body.IDCliente }
  },
  methods: {
    formatCNPJ (val) { return formatCNPJ(val) },
    selecionaCliente (cliente) {
      this.body.IDCliente = cliente.IDCliente
      this.clienteSelecionado = cliente
    },
    enviarProcesso () {
      this.isLoadingPage = true
      this.body = {
        ...this.body,
        Status: 0,
        Produtos: this.items
      }

      this.enviado = true

      novoProcesso(this.body)
        .then((res) => {
          console.log('res', res)
          if (res.result === 0) {
            this.$buefy.notification.open({
              message: 'Proceso cadastrado com sucesso!',
              type: 'is-success'
            })

            this.resposta = res.data
            this.status = 'sucesso'
          } else if (res.result === 1 && res.data.length) {
            this.status = 'pendente'
            this.$store.dispatch('HAS_PENDING_PRODUCTS', true)
            this.$store.dispatch('SET_PENDING_PRODUCTS', {
              clientID: this.body.IDCliente,
              products: res.data
            })
            this.produtosPendentes = res.data
            this.novosProdutos = this.produtosPendentes.map(() => this.objProduto())
            // this.produtosPendentes = processoProdutoNaoCadastrado
            this.$buefy.notification.open({ message: res.message, type: 'is-info' })
          }
          this.isLoadingPage = false
        })
        .catch((error) => {
          console.log(error)
          this.status = 'pendente'
          this.$buefy.notification.open({
            duration: 5000,
            message: 'Ops, algo deu errado.',
            position: 'is-bottom-right',
            type: 'is-danger',
            hasIcon: true
          })
          // this.resposta = respostaProcesso
          this.isLoadingPage = false
        })
    },
    getClientData: debounce(function (name) {
      if (!name.length) {
        this.clientes = []
        return
      }
      this.isFetchingClient = true
      searchCliente(name)
        .then(res => {
          if (res.data.result === 0) {
            this.clientes = res.data.dados
          } else {
            this.clientes = []
          }
          this.isFetchingClient = false
        })
        .catch(() => {
          this.clientes = []
          this.isFetchingClient = false
        })
    }, 500),
    cadastroProdutos () {
      cadastroProdutos(this.novosProdutos)
        .then(res => {
          console.log(res)
          if (res.result === 0) {
            this.status = null
            this.$buefy.notification.open({
              message: 'Produtos cadastrado com sucesso!',
              type: 'is-success'
            })
            this.$router.go()
          }
        })
    },
    objProduto () {
      return {
        Descricao_PTBR: null,
        Descricao_EN: null,
        Descricao_NF: null,
        Codigo_Produto: null,
        NCM: null,
        Unidade_Comercializada: null,
        Linha_Catalago: null,
        Cod_Unid_Qtde: null,
        Cod_Unid_Peso: null,
        Necessita_LI: null,
        IDCliente: this.body.IDCliente
      }
    },
    insertCod (id, index) {
      this.novosProdutos[index].Codigo_Produto = id
    },
    updateItens (data) {
      if (this.formatProdutos(data)) this.enviarProcesso()
    },
    keysIsValid (rows) { return equalsIgnoreOrder(rows[0], this.validKeys) },
    formatProdutos (rows) {
      if (this.keysIsValid(rows) && validaDadosProcesso(rows)) {
        this.items = formatImportedList(rows)
        return true
      } else {
        this.$buefy.notification.open({
          duration: 7000,
          message: 'Formato de planilha inválido.',
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: true
        })
        return false
      }
    }
  }
}
</script>
